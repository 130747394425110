
const Loading = (props) => {
    return (
        <div className="lds-facebook">
            <div className="bg-blue-800"></div>
            <div className="bg-blue-800"></div>
            <div className="bg-blue-800"></div>
        </div>    )
}

export default Loading